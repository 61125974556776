(function ($) {
 
 
var unique = 0;
var types = $.fn.dataTable.ext.type;
 
// Using form $.fn.dataTable.enum breaks at least YuiCompressor since enum is
// a reserved word in JavaScript
$.fn.dataTable['enum'] = function ( arr ) {
    var name = 'enum-'+(unique++);
    var lookup = window.Map ? new Map() : {};
 
    for ( var i=0, ien=arr.length ; i<ien ; i++ ) {
        lookup[ arr[i] ] = i;
    }
 
    // Add type detection
    types.detect.unshift( function ( d ) {
        return lookup[ d ] !== undefined ?
            name :
            null;
    } );
 
    // Add sorting method
    types.order[ name+'-pre' ] = function ( d ) {
        return lookup[ d ];
    };
};
 
 
})(jQuery);
